@font-face {
    font-family: "Poppins-Bold";
    src: url(./fonts/Poppins-Bold.ttf) format('truetype');
}

@font-face {
    font-family: "Poppins-Regular";
    src: url(./fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: "Poppins-Light";
    src: url(./fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
    font-family: "Poppins-Medium";
    src: url(./fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url(./fonts/Poppins-SemiBold.ttf) format('truetype');
}

.superfanWraper *{
    color: white;
}

.superfanWraper input::placeholder{
    color: rgba(255, 255, 255, 0.295);
}

.toSameBorderRadius{
    border-radius: 0.2rem;
}

.superfanWraper h1{
    font-family: 'Poppins-Bold';
    font-size: 1.5rem;
    margin: 0;
}

.superfanWraper h2{
    font-family: 'Poppins-Bold';
    font-size: 1.2rem;
    margin: 0;
}

.superfanWraper h5{
    font-family: 'Poppins-Regular';
    font-size: 0.9rem;
    margin: 0;
}

.superfanWraper h6{
    font-family: 'Poppins-Regular';
    font-size: 0.6rem;
    margin: 0;
}


.superfanWraper h3{
    font-family: 'Poppins-Light';
    font-size: 1rem;
    margin: 0;
}

.superfanWraper p{
    font-family: 'Poppins-Light';
    font-size: 0.8rem;
    font-weight: 100;
    line-height: 1.25;
    margin: 0;
}

.lottieContainer *{
    fill: rgba(255, 255, 255, 1);
}

